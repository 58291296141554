import React, {Component, useEffect} from "react";
import {useForm} from "react-hook-form";
import {Link, navigate} from "gatsby"
import Layout from '../components/layout'
import {GlobalDispatchContext, GlobalStateContext} from '../context/context'
import { Helmet } from 'react-helmet';

const LoginPage = () => {

  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);

  const {register, handleSubmit, watch, formState: {
    errors
  }} = useForm();



  const onSubmit = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username:data.username, password:data.password })
    };    
    
    
    fetch("https://lead5050.staging.parachute.net/wp-json/jwt-auth/v1/token",requestOptions)
    .then(response => response.json())
    .then(data => {
      console.log()
      if (data.token != null){
        dispatch({type: "UPDATE_TOKEN", payload: data})
        dispatch({type: "LOGGED_IN", payload: true})
        navigate("/");


      }else{
        dispatch({type: "UPDATE_LOGIN_ERROR", payload: data.message})

      }
    }).catch((error) => {
      dispatch({type: "UPDATE_LOGIN_ERROR", payload: "Failed to login - please check your details"})

    })
  };

const renderLoginError =() => {
  if (state != null) {

  if (state.loginError != null && state.loginError != ""){
    return(
    <div className="error-box">
      {state.loginError}
    </div>
  )
    }
}
}
if (state != null) {

  return (
    <>
    <Helmet>
    <title>Login</title>
  </Helmet>
    <Layout pageTitle="Home Page">

<div className="container">
	<div className="row">
		<div className="col-md-12">
			
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="col-md-9">
						<div className="user-content text-center block-padding-medium">
							<h1>Login</h1>
							<p>lorem</p>
						</div>
					</div>
				</div>
			</div>


        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="gform-body">
            <div className="gform-fields">
       
     
        <div className="common-background row">

{renderLoginError()}
<div className="gfield col-md-6">
<label>Email Address</label>

        <input
            
              defaultValue={state.username}
              type="text"
              placeholder="Email Address"
              {...register("username", { required: true })}/> {errors.username && <span >this field is required</span>}
     </div>
     <div className="gfield col-md-6">
     <label>Password</label>

     <input
            
              defaultValue={state.password}
              type="password"
              placeholder="Password"
              {...register("password", { required: true })}/> {errors.password && <span >this field is required</span>}
</div>

      </div>
      </div>
      </div>
      <div className="col-md-12 text-center">
      <button>Submit</button>
      </div>
        </form>

        </div>
	</div>
</div> 

<nav className="gf_login_links">
  <Link to="/register" href="" title="New User? Register now">New User? Register now</Link>
  <Link to="/forgottenpassword" title="Stop forgetting your password">Stop forgetting your password
</Link>
</nav>
    </Layout>
    </>
  ) 
}
}
export default LoginPage